<template>
  <div id="app">
<!--    :class="theme"-->
    <div class="wrap">
      <img class="guide-img" :src="basicInfo?.pc_bg"/>
      <div class="download-wp">
        <img   alt="" class="brand" :src="basicInfo?.logo_l">

        <div class="title">{{basicInfo?.appName}}</div>


        <div class="contents">
          Tại đây trực tiếp đầy đủ các trận đấu, giải đấu lớn nhỏ trên toàn thế giới, hình ảnh siêu nhanh , siêu mượt và siêu nét với chế độ Full HD. Đội ngũ bình luận viên Tiếng Việt chuyên nghiệp và hài hước. Dội ngũ chốt kèo bóng đá uy tín tỉ lệ win lên tới 98%. Chốt kèo bóng đá trực tiếp trên livestream với ngũ bình luận viên, trò chuyện và tương tác với nhiều chuyên gia và các Bet Thủ Khắp mọi miền Tổ Quốc
        </div>
        <div class="qr-code-wp">
          <div class="left-part">
            <div class="qr-code">
              <qrcode-vue :value="qrValue" size="138" style="display: flex; align-items: center; justify-content: center"/>
            </div>
<!--            <div class="spec">Hỗ trợ tất cả thiết bị di động hệ iOS &Android</div>-->
          </div>
          <div class="right-part">
            <div class="spec">QUÉT MÃ QR</div>
            <div class="device" style="margin-bottom: 10px">
              <div class="ios"></div>
              <div class="text">IOS</div>
            </div>
            <div class="device">
              <div class="android"></div>
              <div class="text">Android</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import { mapState } from 'vuex'
export default {
  name: 'App',
  components: {
    QrcodeVue,
  },
  computed: {
    ...mapState(["basicInfo"])
  },
  data() {
    return {
      theme: '',
      qrValue: '',
    }
  },
  methods: {
    init() {
      this.$store.dispatch('getBasicInfo').then((res) => {
        this.$store.commit('SET_BASIC_INFO', res.data);
        this.theme = this.basicInfo.theme;
        // 创建一个新的 link 元素
        var linkElement = document.createElement('link');

        // 设置 link 元素的属性
        linkElement.rel = 'shortcut icon';
        linkElement.href = this.basicInfo.logo_s; // 替换为你的 favicon.ico 文件的路径

        // 获取文档头部
        var headElement = document.head || document.getElementsByTagName('head')[0];

        // 删除现有的 favicon，如果存在
        var existingFavicon = document.querySelector("link[rel='shortcut icon']");
        if (existingFavicon) {
          headElement.removeChild(existingFavicon);
        }

        // 将新的 link 元素添加到文档头部
        headElement.appendChild(linkElement);
      })
    }
  },
  created() {
    this.qrValue = window.location.origin;
  },
  mounted() {
    this.init();
  }
}
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100vh;
  background: url("~@/assets/images/bg.png") no-repeat center;
  background-size: cover;
}
.wrap {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.guide-img {
  margin-right: 56px;
  width: 974px;
  height: 665px;
}
.download-wp {
  width: 490px;
  height: 685px;
  background: url("./assets/images/device-bg.png") no-repeat;
  background-size: 100%;

  .brand {
    display: block;
    margin: 52px auto 20px;
    width: 288px;
    height: 86px;
  }

  .title {
    height: 34px;
    line-height: 34px;
    text-align: center;
    font-size: 34px;
    font-weight: 600;
    background: linear-gradient(to right, #690000, #C70000, #C70000, #010101);
    -webkit-background-clip: text;
    color: transparent;
  }

  .sub-title {
    line-height: 30px;

    text-align: center;
    font-size: 22px;
  }

  .contents {
    margin: auto;
    padding: 5px 37px 26px 37px;
    width: 410px;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
  }

  .qr-code-wp {
    margin: auto;
    display: flex;
    justify-content: space-between;
    width: 410px;
    .left-part {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 158px;
      height: 158px;
      background: linear-gradient(to right, #F36F21, #F7EC13, #F36E21);
      border-radius: 16px;
      .qr-code {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 140px;
        height: 140px;
        border-radius: 16px;
        background: #ffffff;
      }
      .spec {
        padding-top: 12px;
        font-size: 14px;
        letter-spacing: 0.5px;
        text-align: center;
        line-height: 18px;
      }
    }

    .right-part {
      .spec {
        padding-bottom: 5px;
        font-size: 28px;
        text-align: center;
        line-height: 34px;
        font-weight: 600;
        background: linear-gradient(to right, #690000, #C70000, #C70000, #010101);
        -webkit-background-clip: text;
        color: transparent;
      }
      .device {
        display: flex;
        align-items: center;
        width: 223px;
        height: 52px;
        background: url("~@/assets/images/btn-bg.png") no-repeat center;
        background-size: 100%;
        .ios, .android {
          margin: 0 7px 0 24px;
          width: 44px;
          height: 44px;
        }
        .ios {
          background: url("~@/assets/images/apple.png") no-repeat center;
          background-size: contain;
        }
        .android {
          background: url("~@/assets/images/android.png") no-repeat center;
          background-size: contain;
        }
        .text {
          width: 128px;
          line-height: 52px;
          font-size: 34px;
          text-align: center;
          background: linear-gradient(to right, #690000, #C70000, #C70000, #010101);
          -webkit-background-clip: text;
          color: transparent;
        }
      }
    }
  }
}


</style>
